
html{
  scroll-behavior:smooth;
}

/* @charset "utf-8"; */
/* CSS Document */

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: local('Poppins'), url(./fonts/Poppins-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins'), url(./fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins'), url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: "Poppins Medium";
  src: url("./fonts/Poppins-Medium.ttf");
  font-weight: 100;
}
.font-popins-b {
  font-family: "Poppins-Bold";
}
.font-popins-sb {
  font-family: "Poppins-SemiBold";
}

.font-popins-m {
  font-family: "Poppins Medium";
}

@font-face {
  font-family: "Poppins Normal";
  src: url("./fonts/Poppins-Regular.ttf");
  font-weight: 100;
}
.font-popins-r {
  font-family: "Poppins Normal";
}
.bg-color-grey{
  color:#F1F3F8;
}
.color-grey{
  color:#7A7A7A;
}
.btn:hover {
  color: #fff !important;
}
body {
  margin: 0;
  font-family: "Poppins-Regular"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white
}

.loader-box{
  position: relative;
  top: 45%;
}

.quiz-border{
  border: 1px dashed #1ed384 !important;
  background: #d7ffe5 !important;
  border-radius: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*                                        custom  fixed                                             */

.main-text{
  color: #302D3A !important;
}

.duration a, .duration{
  color: #1dbd78 !important;
  text-decoration: none;
}

.success{
  background-color: #43C8B1 !important;
  color: white;
}

.error{
  background: #1ed384 !important;
  color:white;
}

.success-text{
  color:  #43C8B1 !important;
}

.s-component{
  max-height: 41px;
  min-height: 41px;
}

.bg-shade{
 background: #EFEFF6;
}

.col-2 .border , .col-12 .border{
  border: 1px solid #43C8B1 !important;
  border-radius: 5px
}

.p-medium{
  font-family: Poppins-Medium;
}

.p-bold{
  font-family: Poppins-Bold
}

.p-regular{
  font-family: Poppins-Regular
}

.p-extra-bold{
  font-family: Poppins-ExtraBold
}

.p-semi-bold{
  font-family: Poppins-SemiBold
}

.p-light{
  font-family: Poppins-Light
}

.p-medium{
  font-family: "Poppins-Medium";
}

.p-bold{
  font-family: "Poppins-Bold";
}

.p-regular{
  font-family: "Poppins-Regular";
}

.p-extra-bold{
  font-family: "Poppins-ExtraBold";
}

.p-semi-bold{
  font-family: "Poppins-SemiBold";
}

.p-light{
  font-family: "Poppins-Light";
}

.f-12{
  font-size: 12px;
}

.f-22{
  font-size: 22px ;
}

.f-16{
  font-size: 16px ;
}

.f-14{
  font-size: 14px ;
}

.f-24{
  font-size: 24px ;
}

.f-18{
  font-size: 18px ;
}

.f-20{
  font-size: 20px ;
}

.f-40{
  font-size: 40px;
}

/*                                       custom styles                                                                      */
.tab-content{
  width : 100% !important;
}

.tab-divider{
  height: 45px;
  background: #F1F3F8;
}

.card-body{
  padding: 0 !important;
}
.card-body .card-body{
  padding: 1rem !important;
}

.card{
  border-left: none !important;
  border-right: none !important;
}

.card .card{
  border-left: 1px solid rgba(0,0,0,.125) !important;
  border-right: 1px solid rgba(0,0,0,.125) !important;
}

div.w-25.side-bar{
  min-width: 360px !important;
}

.card .card .card-header{
  border-left: none;
  border-right: none;
}

.card-header{
  border-left: 1px solid rgba(0,0,0,.125);
  border-right: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}

.card .card .card-body .row{
  padding: 5px 2px;
}

input.border-1:focus{
  outline :none !important;
  border-radius: 0 !important ;
}

.pb-2.pb-cust{
  padding-bottom: 10px !important
}

input, .bg-none{
border: none !important;
background: #F1F3F8;
border-radius: 0

}
.accordion{
  color:#302D3A;
}
.queIndicator:focus{
  background-color: #1dbd78 !important;
  color: white !important
}

.selected{
  background: #1dbd78 !important;
  color: white !important
}

.option-border{
  border: 1px solid #CAC6D4;
  border-radius: 8px;
}

@media (min-width:1200px) {
  div.questions div.col-2.px-0.my-1{
    flex: 0 0 5% !important;
  }
}

@media (max-width:500px) {
  .quiz-border{
    font-size: 12px!important
  }

  .mb-100{
    width: 100%  !important;
  }
  .f-20{
    font-size:18px ;
  }

  .loader-box{
    top: 40%;
  }

}
